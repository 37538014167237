class LocalStorage {
  static setUser (user) {
    window.localStorage.setItem('ch:user', JSON.stringify(user))
  }

  static setUserId (id) {
    window.localStorage.setItem('ch:userId', id)
  }

  static getUser () {
    const strUser = window.localStorage.getItem('ch:user')
    if (strUser) return JSON.parse(strUser)
  }

  static getUserId () {
    return window.localStorage.getItem('ch:userId')
  }

  static async removeUser () {
    window.localStorage.removeItem('ch:user')
    return
  }

  static removeUserId () {
    window.localStorage.removeItem('ch:userId')
  }

  static setRedirect (path) {
    window.localStorage.setItem('ch:redirect', JSON.stringify(path))
  }

  static getRedirect () {
    const path = window.localStorage.getItem('ch:redirect')
    if (path) return JSON.parse(path)
  }

  static removeRedirect () {
    window.localStorage.removeItem('ch:redirect')
  }

  static setHiddenChallengeUpsells (challengeId) {
    let arr = JSON.parse(window.localStorage.getItem('ch:mapUpsells'))
    if (arr && arr.length) {
      arr.push(challengeId)
    } else {
      arr = [challengeId]
    }
    window.localStorage.setItem('ch:mapUpsells', JSON.stringify(arr))
  }

  static removeHiddenChallengeUpsells (challengeId) {
    let arr = JSON.parse(window.localStorage.getItem('ch:mapUpsells'))
    if (!arr || arr === null || (arr && !arr.length)) return
    const reduced = arr.filter(item => item !== challengeId)
    window.localStorage.setItem('ch:mapUpsells', JSON.stringify(reduced))
  }

  static getHiddenChallengeUpsells (challengeId) {
    const arr = JSON.parse(window.localStorage.getItem('ch:mapUpsells'))
    if (!arr || !arr.length) return false
    return arr.includes(challengeId)
  }

  static setCookieConsent (val) {
    window.localStorage.setItem('ch:cookie-consent', val)
  }

  static getCookieConsent () {
    const data = window.localStorage.getItem('ch:cookie-consent') === 'true'
    if (data) return JSON.parse(data)
  }

  static setAdminUser (user) {
    window.localStorage.setItem('ch:admin', JSON.stringify(user))
  }

  static getAdminUser () {
    const strUser = window.localStorage.getItem('ch:admin')
    if (strUser) return JSON.parse(strUser)
  }

  static removeAdminUser () {
    return window.localStorage.getItem('ch:admin')
  }

  static setChallengeCache (challenges) {
    window.localStorage.setItem('ch:challenge-cache', JSON.stringify(challenges))
  }

  static getChallengeCache () {
    const data = window.localStorage.getItem('ch:challenge-cache')
    if (data) return JSON.parse(data)
  }

  static removeChallengeCache () {
    window.localStorage.removeItem('ch:challenge-cache')
  }

  static setGarminData (data) {
    window.localStorage.setItem('ch:garmin', JSON.stringify(data))
  }

  static getGarminData () {
    const data = window.localStorage.getItem('ch:garmin')
    if (data) return JSON.parse(data)
  }

  static removeGarminData () {
    window.localStorage.removeItem('ch:garmin')
  }

  static setStripePaymentIntent (data) {
    window.localStorage.setItem('ch:stripe-pi', JSON.stringify(data))
  }

  static getStripePaymentIntent () {
    const data = window.localStorage.getItem('ch:stripe-pi')
    if (data) return JSON.parse(data)
  }

  static removeStripePaymentIntent () {
    window.localStorage.removeItem('ch:stripe-pi')
  }

  static setPaymentIntent (type, data) {
    window.localStorage.setItem(`ch:pi-${type}`, JSON.stringify(data))
  }

  static getPaymentIntent (type) {
    const data = window.localStorage.getItem(`ch:pi-${type}`)
    if (data) return JSON.parse(data)
  }

  static removePaymentIntent (type) {
    window.localStorage.removeItem(`ch:pi-${type}`)
  }

  static setAppleLastSync (ts) {
    window.localStorage.setItem(`ch:appleLastSync`, ts)
  }

  static getAppleLastSync () {
    const ts = window.localStorage.getItem(`ch:appleLastSync`)
    if (ts) return parseFloat(ts)
  }

  static setHealthConnectLastSync (ts) {
    window.localStorage.setItem(`ch:healthConnectLastSync`, ts)
  }

  static getHealthConnectLastSync () {
    const ts = window.localStorage.getItem(`ch:healthConnectLastSync`)
    if (ts) return parseFloat(ts)
  }

  static setTempInvitees (challengeId, list) {
    window.localStorage.setItem(`ch:tempInvitees:${challengeId}`, JSON.stringify(list))
  }

  static getTempInvitees (challengeId) {
    const data = window.localStorage.getItem(`ch:tempInvitees:${challengeId}`)
    if (data) return JSON.parse(data)
  }

  static removeTempInvitees (challengeId) {
    window.localStorage.removeItem(`ch:tempInvitees:${challengeId}`)
  }
}
export default LocalStorage
